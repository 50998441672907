import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"

// import "../fontawesome/css/all.min.css"

import "./car-gallery.css"



const Tractorshow = () => (

  <div className="App">
    <Layout>
<Helmet>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.0/jquery.min.js"/>
  <script src="https://cdn.jsdelivr.net/npm/nanogallery2/dist/jquery.nanogallery2.min.js"/>
  <script src="/js/script.js"/>
   <link href="https://cdn.jsdelivr.net/npm/nanogallery2/dist/css/nanogallery2.min.css" rel="stylesheet" type="text/css" />
  

</Helmet>
            <div className="container">
      <div>
        <div className="row">
          <div className="col-md-12">
            <h1>2023 Car Gallery</h1>
              <div id="my_nanogallery2"></div>

            <p>

            </p>
          </div>
        </div>

      </div>

      </div>

    </Layout>

  </div>



)
export default Tractorshow
